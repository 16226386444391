/**
 * ADDI - UI-Foundation
 * Do not edit directly
 * Generated on Tue, 01 Oct 2024 01:50:43 GMT
 */

module.exports = {
  "SizeAddiFontXxxSmall": "0.625rem",
  "SizeAddiFontXxSmall": "0.75rem",
  "SizeAddiFontXSmall": "0.9rem",
  "SizeAddiFontSmall": "1rem",
  "SizeAddiFontBase": "1.25rem",
  "SizeAddiFontLarge": "1.5rem",
  "SizeAddiFontXLarge": "2rem",
  "SizeAddiFontXxLarge": "2.75rem",
  "SizeAddiFontXxxLarge": "3.75rem"
};